import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { injectIntl } from 'gatsby-plugin-intl'
const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="main-container">{children}</div>
      <Footer />
    </>
  )
}

export default injectIntl(Layout)
