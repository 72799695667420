import React from 'react'
import { Link, FormattedMessage } from 'gatsby-plugin-intl'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const getLogo = graphql`
  query {
    fixed: file(relativePath: { eq: "logo2.png" }) {
      childImageSharp {
        fixed(height: 150) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    fixedHeart: file(relativePath: { eq: "heart.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(getLogo)
  return (
    <footer className="footer-2 bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 text-center">
            <Link to="/">
              <Img alt="Logo" fixed={data.fixed.childImageSharp.fixed} />
            </Link>
            <h5 className="fade-1-4">
              <FormattedMessage id="footer.handcrafted" defaultMessage="Handcrafted by Appify-IT" />&copy; 2019
              <br /> <FormattedMessage id="footer.builtWith" defaultMessage="Built with" />
              <Img
                alt="love"
                style={{ marginLeft: '1rem', marginTop: '1rem' }}
                fixed={data.fixedHeart.childImageSharp.fixed}
              />
            </h5>
            <ul className="list-inline social-list mb0">
              <li>
                <a href="https://www.facebook.com/pg/Appify-IT-107329220645962">
                  <i className="ti-facebook" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/appify-it">
                  <i className="ti-linkedin" />
                </a>
              </li>
              <li>
                <a href="mailto:contact@appify-it.com">
                  <i className="ti-envelope" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
