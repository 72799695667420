export default [
  {
    path: '/',
    menuId: 'navBar.home',
    textFr: 'Acceuil'
  },
  {
    path: '/blogs',
    menuId: 'navBar.blog',
    textFr: 'blog'
  },
  {
    path: '/jobs',
    menuId: 'navBar.career',
    textFr: 'carrière'
  },
  {
    path: '/contact',
    menuId: 'navBar.contact'
  }
]
