import React, { useState } from 'react'
import links from '../constants/links'
import Image from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import {
  IntlContextConsumer,
  changeLocale,
  Link,
  injectIntl
} from 'gatsby-plugin-intl'

const languageName = {
  en: 'Eng',
  fr: 'Fr'
}
const getLogos = graphql`
  query Images {
    lightLogo: file(relativePath: { eq: "logo-light.png" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    logoDark: file(relativePath: { eq: "logo-dark.png" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`

const Navbar = ({ intl }) => {
  const data = useStaticQuery(getLogos)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenLanguage, setIsOpenLanguage] = useState(false)
  const toggleNav = () => {
    setIsOpen(isOpen => !isOpen)
    setIsOpenLanguage(false)
  }

  return (
    <div className="nav-container">
      <nav className={' absolute transparent fixed outOfSight scrolled '}>
        <div className={`nav-bar bg-dark ${isOpen && 'nav-open'}`}>
          <div className="module left">
            <Link to="/" className="logo-appify">
              <span className="logo logo-light">
                <Image
                  alt="Appify-it light"
                  fixed={data.lightLogo.childImageSharp.fixed}
                />
              </span>
              <span className="logo logo-dark">
                <Image
                  alt="Appify-it black"
                  fixed={data.logoDark.childImageSharp.fixed}
                />
              </span>
            </Link>
          </div>
          <div
            className={`module widget-handle mobile-toggle right visible-sm visible-xs ${isOpen &&
              'active'}`}
          >
            <i className="ti-menu" onClick={toggleNav} />
          </div>
          <div id="nav_menu" className="module-group right">
            <div className="module left">
              <ul className="menu">
                {links.map(item => (
                  <li key={item.menuId}>
                    <Link to={item.path}>
                      {intl.formatMessage({ id: `${item.menuId}` })}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="module widget-handle language left">
              <ul className="menu">
                <li
                  onClick={() => setIsOpenLanguage(isOpen => !isOpen)}
                  className={
                    isOpenLanguage ? 'has-dropdown toggle-sub' : 'has-dropdown'
                  }
                >
                  <a href="#" onClick={e => e.preventDefault()}>
                    {intl.formatMessage({ id: 'navBar.language' })}
                  </a>
                  <ul>
                    <IntlContextConsumer>
                      {({ languages, language: currentLocale }) =>
                        languages.map(language => (
                          <li key={language}>
                            <a
                              onClick={() => changeLocale(language)}
                              style={{
                                color:
                                  currentLocale === language
                                    ? `#069cd6`
                                    : `white`
                              }}
                            >
                              {languageName[language]}
                            </a>
                          </li>
                        ))
                      }
                    </IntlContextConsumer>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default injectIntl(Navbar)
